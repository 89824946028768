body {
	background-color: #e0e0e3;
}

.wrapper {
	margin: 0 auto;
	max-width: 1100px;
}
@media (max-width: 1120px) {
	.wrapper {
		max-width: 860px;
	}
}

.header {
	line-height: $base-font-size * 2;
	background-color: $header-bg;
	padding: 10px;
	margin: 0;
	
	&:after {
		clear: both;
		content: ' ';
		display: block;
	}
	
    #sidebar-toggle { display: none; }

	.site-title {
		float: left;
		color: $header-fg;
		text-decoration: none;
		font-size: $base-font-size * 1.25;
		line-height: $base-font-size * 2;
	}
	.site-nav {
		text-align:right;
		
		a {
			text-decoration: none;
			color: $header-link;
			padding: 10px 20px;
			white-space: nowrap;
		
			&:hover, &:focus {
				background: $header-bg-hover;
			}
		}
	}
}

$col-main-width: 860px;
$col-secnod-width: 240px;
$hide-sidebar-screen-width: 1120px;

.header-placeholder { position: absolute; }
@media (max-width: $hide-sidebar-screen-width) {
    .header-placeholder { position: static; }
    .header { 
        position: fixed; left: 0; top: 0; right: 0; z-index: 20;
        #sidebar-toggle {
            float: right;
            display: block;
            cursor: pointer;
            margin: -10px;
            margin-left: 0;
            padding: 10px;
            background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIj8+PHN2ZyBmaWxsPSJub25lIiBoZWlnaHQ9IjI0IiBzdHJva2U9IiNGRkYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIyIiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48bGluZSB4MT0iMyIgeDI9IjIxIiB5MT0iMTIiIHkyPSIxMiIvPjxsaW5lIHgxPSIzIiB4Mj0iMjEiIHkxPSI2IiB5Mj0iNiIvPjxsaW5lIHgxPSIzIiB4Mj0iMjEiIHkxPSIxOCIgeTI9IjE4Ii8+PC9zdmc+);
            background-repeat: no-repeat;
            background-position: center center;
            color: transparent;
		}
		
		.site-nav {
			display: none;
		}

		&.expand-sidebar .site-nav {
			display: block;
			animation: ebani-fadein ease .5s 1;
		}
    }
}

.page-content {
	padding: 30px 0;

	.wrapper {
		position: relative;
		background: #FAFAFA;
		box-shadow: 0 1px 4px #999;
		outline: 1px solid #CCC;
		&:after {
			clear:both;
			content: '';
			display:block;
		}
		&:before {
			content: '';
			display:block;
			position: absolute;
			width: $col-secnod-width;
			height: 100%;
			right: 0;
			top: 0;
			background: #EEE;
		}
	}
	
	img {
		max-width:100%;
	}
}

// Colum
.col-main {
	position: relative;
	box-sizing: border-box;
	padding: 30px;
	width: $col-main-width;
	float: left;
	animation: ebani-descend ease .5s 1;
	h1, h2, h3, h4, .post-meta, .rss-link {
		animation: ebani-ascend ease .5s 1;
	}
	article, .post-excerpt {
		table, pre, img {
			animation: ebani-ascend ease .3s 1;
		}
	}
}
.col-second {
	position: relative;
	box-sizing: border-box;
	float: right;
	width: $col-secnod-width;
	padding: 20px;
	background: #EEE;
	z-index: 10;
}
.col-box{
	color: #666;
	font-size: $small-font-size;
	padding-bottom: 20px;
	
	p {
	    margin: 0;
	}
    ul {
        padding-left: 20px;
        margin: 0;
    }
	
	&:not(:first-child) {
    	border-top: 1px solid #ccc;
    	padding-top: 20px;
    }
    
    .col-box-title {
    	color: #333;
	    margin-bottom: 10px;
	    font-size: $small-font-size * 1.1;
    }
}
@media (max-width: $hide-sidebar-screen-width) {
	.page-content .wrapper {
        overflow: hidden;
        .col-second, &:before {
            z-index: 10;
            transition: transform .4s;
            transform: translateX( $col-secnod-width );
    	}

        &.expand-sidebar {
            &:before {
                box-shadow: 0 0 4px #999;
            }
            .col-second, &:before {
                transform: translateX( 0 );
            }
        }
    }
	.col-main {
		width: 100%;
	}
	.col-second {
        margin-left: - $col-secnod-width;
    }
}
.col-box-author {
	text-align: center;
	.avatar {
		width: 60px;
		height: 82px;
		margin: 20px auto;
		display: block;
	}
	.name {
		font-size: $base-font-size;
	}
	.contact {
		margin: 10px -10px 0 -10px;
		a {
			background: #999;
			color: #eee;
			padding: 5px 8px;
			text-decoration: none;
			border-radius: 5px;
    		white-space: nowrap;
			
			&:hover {
				transition: background 0.3s ease;
				background: #666;
			}
		}
	}
}

// footer
.footer {
	padding: 60px 0;
	text-align:center;
	color:#666;
	font-size:$small-font-size;
}

@import "post", "home";

@include media-query($on-laptop) {
	.page-content {
		padding: 0;
	}
	.footer {
		padding: 15px 0 ;
	}
}
@include media-query($on-palm) {
	.header {
		&, .site-nav{
			text-align:center;
		}
		.site-title {
			float: none;
		}
	}
	.col-main {
		padding: 10px;
	}
	.col-box {
		width: 100%;
		float: none;
		margin-bottom: 20px;
	}
	pre {
		padding: 10px;
	}
}
